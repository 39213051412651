import React, { useState, useEffect, useRef } from 'react'
import { useSearchFeedsQuery } from '../api/api'
import { CloseIcon } from './atoms/icons/closeIcon/CloseIcon'
import './atoms/icons/closeIcon/style.scss'

interface FeedSearchProps {
  onSearchResults: (results: any[]) => void
}

const FeedSearch: React.FC<FeedSearchProps> = ({ onSearchResults }) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)
  
  // Auto-focus the input field when component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])
  
  // Skip the query if the search term is empty
  const { data: searchResults, isLoading } = useSearchFeedsQuery(debouncedSearchTerm, {
    skip: !debouncedSearchTerm,
  })

  // Debounce search term to avoid too many API calls
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
      
      // If search term is empty, reset results
      if (!searchTerm) {
        handleReset()
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [searchTerm])

  // Pass search results to parent component
  useEffect(() => {
    if (searchResults) {
      onSearchResults(searchResults)
    }
  }, [searchResults, onSearchResults])

  // Reset search
  const handleReset = () => {
    setSearchTerm('')
    setDebouncedSearchTerm('')
    onSearchResults([]) // This will trigger the parent component to show all feeds
    
    // Re-focus the input after reset
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <div className="feed-search">
      <div className="search-input-container">
        <input
          ref={inputRef}
          type="text"
          placeholder="Search feeds by name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
          autoFocus
        />
        {searchTerm && (
          <div className="reset-icon">
            <CloseIcon onClick={handleReset} />
          </div>
        )}
      </div>
      {isLoading && <span className="loading-indicator">Searching...</span>}
    </div>
  )
}

export default FeedSearch 