import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useGetFeedsListQuery } from '../../api/api'
import LoadingSpinner from '../../components/atoms/loadingSpinner/LoadingSpinner'
import { TfeedListItem } from '../../types/Tfeeds'
import SingleFeed from './singleFeed/SingleFeed'
import FeedSearch from '../../components/FeedSearch'
import '../../components/FeedSearch.scss'

const FeedsList: React.FC = () => {
  const [feeds, setFeeds] = useState<TfeedListItem[] | never[]>([])
  const [isSearching, setIsSearching] = useState<boolean>(false)

  const { data: feedsList, isError, isLoading, isSuccess } = useGetFeedsListQuery()

  // Set feeds when data is loaded or when search is reset
  useEffect(() => {
    if (isSuccess && feedsList) {
      if (!isSearching || feeds.length === 0) {
        setFeeds(feedsList)
      }
    }
  }, [feedsList, isSuccess, isSearching, feeds.length])

  const handleSearchResults = (results: TfeedListItem[]) => {
    if (results.length > 0) {
      setIsSearching(true)
      setFeeds(results)
    } else if (isSuccess && feedsList) {
      // If search is cleared, show all feeds
      setIsSearching(false)
      setFeeds(feedsList)
    }
  }

  isError && <>Error: fetch import run list list from server</>

  return (
    <div className='FeedsList pageContainer'>
      <div className='buttonsContainer'>
        {localStorage.userRole !== 'view' && (
          <Link to='/addnewfeed' className='AddNewFeed'>
            <button className='buttonBase'>Add new +</button>
          </Link>
        )}
      </div>
      
      <div className='searchContainer'>
        <FeedSearch onSearchResults={handleSearchResults} />
      </div>
      
      <div className='table'>
        <div className='tableHeader'>
          <ul>
            <li>Status</li>
            <li>Name</li>
            <li>Type</li>
            <li>Reader</li>
            <li>Publisher</li>
            <li>Last import run</li>
            <li>Edit</li>
            <li>Options</li>
          </ul>
        </div>
        {isLoading && !isSearching && <LoadingSpinner />}
        {!!feeds?.length &&
          feeds.map((feed: TfeedListItem) => (
            <SingleFeed
              key={feed.id}
              id={feed.id}
              name={feed.name}
              publisher={feed.publisher}
              reader={feed.reader}
              url={feed.url}
              importRun={feed.importRuns}
              isActive={feed.isActive}
              publisherConfig={feed.publisherConfig}
            />
          ))}
        {feeds.length === 0 && !isLoading && (
          <div className="no-results">No feeds found. Try a different search term.</div>
        )}
      </div>
    </div>
  )
}

export default FeedsList
