export default {
  jobsContext: '',
  id: 'handle',
  title: 'title',
  publishDate: 'publish_date',
  embeddedHtml: 'description',
  company: 'company',
  companyCode: 'company_code',
  showUrl: 'show_url',
  applyUrl: 'apply_url',
  applyEmail: 'apply_email',
  language: 'language',
  referenceNumber: 'reference_number',
  locationContext: 'locations',
  contactContext: 'contact',
  employmentStartDate: 'employment_start_date',
  headcount: 'headcount',
  keywords: 'keywords',
  campaignTags: 'campaign_tags',

  positionType: {
    id: 'position_type.id',
    title: 'position_type.title',
  },

  customDataField: {
    name: 'name',
    value: 'value',
  },

  industry: { id: 'idustry.id', title: 'industry.title' },

  seniority: { id: 'seniority.id', title: 'seniority.title' },

  fieldOfWork: {
    id: 'field_of_work.id',
    title: 'field_of_work.title',
  },

  contractType: {
    id: 'contract_type.id',
    title: 'contract_type.title',
  },

  locations: {
    city: 'city',
    countryCode: 'country_code',
    country: 'country',
    street: 'street',
    streetNumber: 'street_number',
    region: 'region',
    zip: 'zip',
  },
  positionTypesContext: 'position_types',
  positionTypes: { id: 'position_types.id', title: 'position_types.title' },
  combinedPositionTypes: 'combined_position_types',

  contact: {
    name: 'contact.name',
    url: 'contact.url',
    email: 'contact.email',
    phone: 'contact.phone',
  },

  salary: {
    minimum: 'salary.minimum',
    maximum: 'salary.maximum',
    currency: 'salary.currency',
    interval: 'salary.interval',
  },

  content: {
    hiringOrganizationTitle: 'content.hiringOrganizationTitle',
    hiringOrganization: 'content.hiringOrganization',
    responsibilitiesTitle: 'content.responsibilitiesTitle',
    responsibilities: 'content.responsibilities',
    qualificationsTitle: 'content.qualificationsTitle',
    qualifications: 'content.qualifications',
    incentivesTitle: 'content.incentivesTitle',
    incentives: 'content.incentives',
    contactTitle: 'content.contactTitle',
    contact: 'content.contact',
  },

  branding: {
    primaryColor: 'branding.primary_color',
    secondaryColor: 'branding.secondary_color',
    headerImage: 'branding.header_image',
    sliderImages: 'branding.slider_images',
    backgroundImage: 'branding.background_image',
    video: 'branding.video',
    logo: 'branding.logo',
  },
}
