import { useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
// Import
import 'react-confirm-alert/src/react-confirm-alert.css'
// Import css
import InfiniteScroll from 'react-infinite-scroller'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'

import {
  useAbortImportRunMutation,
  useNextImportRunQuery,
  useSingleFeedAllImportRunsQuery,
  useTriggerImportRunMutation,
  useTriggerTestImportRunMutation,
} from '../../../../api/api'
import { DividerLine } from '../../../../components/atoms/lines/DividerLine'
import LoadingSpinner from '../../../../components/atoms/loadingSpinner/LoadingSpinner'
import { TresponseError } from '../../../../types/Tgeneral'
import { NextImportRun } from './components/NextImportRun'
import { IimportRun, SingleRun } from './components/SingleRun'

const notify = (message: string, autoClose = 3000) => toast(message, { autoClose })

const ImportRuns: React.FC = () => {
  const { feedId, feedName } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const dateFilter = searchParams.get('date')

  const itemsPerPage = 60
  const [records, setrecords] = useState(itemsPerPage)

  const [importRuns, setImportRuns] = useState<[IimportRun]>()
  const [hasMore, setHasMore] = useState(true)
  const [selectedDate, setSelectedDate] = useState<string | null>(dateFilter)

  const {
    data: importRunsList,
    isError,
    isLoading,
    isSuccess,
    error,
  } = useSingleFeedAllImportRunsQuery({ feedId, date: selectedDate })

  const { data: nextImportRun } = useNextImportRunQuery(feedId)

  const [
    triggerImportRun,
    {
      data: triggerResponse,
      isLoading: isTriggering,
      error: triggerError,
      isSuccess: successTrigger,
    },
  ] = useTriggerImportRunMutation()

  const [
    triggerTestImportRun,
    {
      data: testTriggerResponse,
      isLoading: isTestTriggering,
      error: testTriggerError,
      isSuccess: successTestTrigger,
    },
  ] = useTriggerTestImportRunMutation()

  const [abortImportRun] = useAbortImportRunMutation()

  useEffect(() => {
    if (error) {
      const errorObject: TresponseError = { ...triggerError } // prevents typescript errors
      errorObject?.data?.message && notify(errorObject.data.message)
    }

    triggerResponse && notify(triggerResponse.message)
  }, [error, isTriggering, successTrigger, triggerError, triggerResponse])

  useEffect(() => {
    if (testTriggerError) {
      const errorObject: TresponseError = { ...testTriggerError } // prevents typescript errors
      errorObject?.data?.message && notify(errorObject.data.message)
    }

    testTriggerResponse && notify(testTriggerResponse.message)
  }, [isTestTriggering, successTestTrigger, testTriggerError, testTriggerResponse])

  useEffect(() => {
    isSuccess && setImportRuns(importRunsList)
  }, [importRunsList, isSuccess])

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = e.target.value || null
    setSelectedDate(newDate)
    
    if (newDate) {
      setSearchParams({ date: newDate })
    } else {
      searchParams.delete('date')
      setSearchParams(searchParams)
    }
    
    // Reset pagination when filter changes
    setrecords(itemsPerPage)
    setHasMore(true)
  }

  if (isError) {
    return <h1>{JSON.stringify(error, null, 2)}</h1>
  }

  return (
    <div className='ImportRuns pageContainer'>
      <div className='breadcrumbs'>
        <span>
          <Link to='/feeds'>
            <p>feeds / </p>
          </Link>
        </span>
        <span>
          {feedName} : {feedId}
        </span>
      </div>
      <h1>
        Import runs for <span>{feedName}</span>
      </h1>
      
      <div className='filterContainer'>
        <div className='dateFilter'>
          <label htmlFor="dateFilter">Filter by date: </label>
          <input
            type="date"
            id="dateFilter"
            value={selectedDate || ''}
            onChange={handleDateChange}
          />
          {selectedDate && (
            <button 
              className="clearFilter" 
              onClick={() => {
                setSelectedDate(null);
                searchParams.delete('date');
                setSearchParams(searchParams);
                setrecords(itemsPerPage);
                setHasMore(true);
              }}
            >
              Clear filter
            </button>
          )}
        </div>
      </div>
      
      {localStorage.userRole !== 'view' && (
        <div className='triggers'>
          <div className='manualTrigger'>
            {isTriggering ? (
              <LoadingSpinner />
            ) : (
              <button className='buttonBase' onClick={submitTriggerNewRun}>
                Trigger manualy
              </button>
            )}
          </div>
          <div className='manualTrigger'>
            {isTestTriggering ? (
              <LoadingSpinner />
            ) : (
              <button className='buttonBase triggerTest' onClick={submitTestTriggerNewRun}>
                Trigger Test Import Run
              </button>
            )}
          </div>
        </div>
      )}
      <div>
        <NextImportRun nextImportRun={nextImportRun} />
      </div>

      <DividerLine />
      {!importRunsList?.length ? (
        <h1 className='emptyList'>No Import Runs for this feed</h1>
      ) : (
        <div className='table'>
          <div className='tableHeader'>
            <ul>
              <li>Id</li>
              <li>Status</li>
              <li>Start time</li>
              <li>End time</li>
              <li>Result</li>
            </ul>
          </div>
          <div className='rows'>
            {nextImportRun?.status === 'running' && (
              <div className='abortImportRun'>
                {localStorage.userRole !== 'view' && (
                  <div className='abort' onClick={submitAbortImportRun}>
                    <img
                      width='23'
                      src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADLklEQVR4nO2Zy2oUQRSGPxQvYLyBCm4UfQZNxIXP4GWry0Sj+yiIN5J1En2JxFt8EcE4UzWGATW60EhMFirEiBj5TQV6yurMdHdNt8L8UBAmXef8dTvnr1PQQw89FMYabDHQb+CGhScGahaWLay6tux+e6xv9K36UDUacNjAmIX3FtYytnfqKxulE6/BfgP3DazkIN7SDKzI1gvYVwr5Opy38Kko8UBbkO2uEV+DrZqpTWbyi4EpA4N1ONmEg89hm5r+dmdkyMC0ha+b2JmUr6jk38JOAzMpTj824Oos7OrUnr41cE2znjKIGfmMOfPPAk6+G7hjoC+vbQN9Fu65SPXXIKKsRMq20X49TSRYuJmyEhMxDqxv1Bg4Eot8HS5Y+JEygF8NOJfL8GvYa+CDZ3CxBsfLIG8Tq62wndm4hQf+no+5bULkDfx0ibHlTGgb58mwLUlKB7bb5C1cCp0JccmUsd0stITKItEmC3mhCTsMvPG+GaMTSGRJpyQ7K85TEvkNGLjsfTffkQBUxvQzbJYkFYN8Ike0ZOwanKAdnCROOpmiZPIbMPDQ63OddpCe9zoNUgH50DbSfYJ2MFD3nPVTAXmhAae8vjXawcDnZKc5OEAF5IVZOOQnUtohkES2UwH5jXDqJ1O6PYBY5HMPoMgWikk+9xbKe4hjk899iANhdKgK8oKFK3nCqJ/IpqsgL4iwN4ARskoJpfM0KdFN8mZdSnzLLCVCYk4X8DLJCxaGc4m5FDm98Ap2l0W+uS6n5z37o4UuNBbulkFeMHC70IUmpRqx6m5KXSVv4UzAx2RmQ03Y41/q/dYF8kcDZcuF3HVTlVVU2tiE/MVY5OfgmIWG50O+zxYyrOULkO/8jtrhtjGwGJio8VilxVBd9M+ZyKNWvWhzK1RatPA0WpHXOQoWd1U90M0pS9XCJalhP1QmycsnMeFWYiLtTChrujus9MuAlKRWR82pygH3v0d+hrWte348enk9CdUq08riBdtC7jpoViisRX5imiztiSmQsUd97dQh8Xn1reSRz4dElp6VVLdxEvilhaXEM+uS+03/G5Gq/CeeWXvogf8fvwGK9qy9+kC4PwAAAABJRU5ErkJggg=='
                    />
                  </div>
                )}
              </div>
            )}
            {isLoading && <LoadingSpinner />}
            {importRuns && (
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMore}
                loader={<LoadingSpinner key={uuidv4()} />}
                useWindow={true}
              >
                <div className='tableContent'>{showItems(importRuns, records)}</div>
              </InfiniteScroll>
            )}
          </div>
        </div>
      )}
      <ToastContainer limit={3} position='top-center' />
    </div>
  )

  function loadMore() {
    if (importRuns && records >= importRuns?.length) {
      setHasMore(false)
    } else {
      setTimeout(() => {
        setrecords(records + itemsPerPage)
      }, 200)
    }
  }
  function triggerAbortImportRun() {
    abortImportRun(feedId)
  }
  function submitTriggerNewRun() {
    confirmAlert({
      title: 'Trigger an Import run',
      message: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => triggerImportRun(feedId),
        },
        {
          label: 'No',
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: 'overlay-custom-class-name',
    })
  }

  function submitTestTriggerNewRun() {
    confirmAlert({
      title: 'Trigger a Test Import run (2 Job postings)',

      message: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => triggerTestImportRun(feedId),
        },
        {
          label: 'No',
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: 'overlay-custom-class-name',
    })
  }

  function submitAbortImportRun() {
    confirmAlert({
      title: 'Abort current running import run for this feed',
      message: 'Are you sure?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => triggerAbortImportRun(),
        },
        {
          label: 'No',
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: 'overlay-custom-class-name',
    })
  }
}

function showItems(runsList: IimportRun[], records: number) {
  const items = []
  for (let i = 0; i < records; i++) {
    if (runsList[i]?.id) {
      items.push(
        <SingleRun
          key={runsList[i].id}
          id={runsList[i].id}
          status={runsList[i].status}
          startTime={runsList[i].startTime}
          endTime={runsList[i].endTime}
          result={runsList[i].result}
        />,
      )
    }
  }
  return items
}

export default ImportRuns
