export const CloseIcon = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div className='CloseIcon' onClick={onClick} style={{ cursor: 'pointer' }}>
      <svg 
        width="16" 
        height="16" 
        viewBox="0 0 16 16" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          d="M12.5 3.5L3.5 12.5M3.5 3.5L12.5 12.5" 
          stroke="#666666" 
          strokeWidth="2" 
          strokeLinecap="round" 
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default CloseIcon 