import React from 'react'
import './style.scss'

interface FeedTypeIconProps {
  type: 'free_bundle' | 'indeed_campaign' | string
  size?: 'small' | 'medium' | 'large'
}

const FeedTypeIcon: React.FC<FeedTypeIconProps> = ({ type, size = 'medium' }) => {
  const getIcon = () => {
    switch (type) {
      case 'free_bundle':
        return (
          <div className={`letter-icon ${size} free-bundle`}>
            F
          </div>
        )
      case 'indeed_campaign':
        return (
          <div className={`letter-icon ${size} indeed-campaign`}>
            I
          </div>
        )
      default:
        return null
    }
  }

  if (!type) return null

  return (
    <div className="feed-type-icon-container" title={`Type: ${type}`}>
      {getIcon()}
    </div>
  )
}

export default FeedTypeIcon 